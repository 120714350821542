import React, { lazy, useState, useEffect, FunctionComponent } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import LeftSidebar from './theme/Layout/LeftSidebar';
import MinimalLayout from './theme/Layout/Minimum';
import { ProtectedRoute, AuthRoute } from './api';
import { Trans } from './i18n';

const Emails = lazy(() => import('./pages/Emails'));
const Lists = lazy(() => import('./pages/Lists'));
const Domains = lazy(() => import('./pages/Domains'));

const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const Settings = lazy(() => import('./pages/Settings'));

const Api = lazy(() => import('./pages/Api'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SuspenseLoading = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
              <div className="d-flex align-items-center flex-column px-4">
                <ClimbingBoxLoader color="#3c44b1" loading />
              </div>
              <div className="text-muted font-size-xl text-center pt-3">
                <span className="font-size-lg d-block text-dark">
                  <Trans>Please wait...</Trans>
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Routes: FunctionComponent = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 1,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.35,
  };

  return (
    <>
      {/* <AnimatePresence> cause double render of routes!!! */}
      <Switch location={location} key={location.pathname}>
        <Redirect exact from="/" to="/dashboard" />

        {/* <Route path={['/Homepage', '/ProductsFilters']}> */}
        {/*  <PresentationLayout> */}
        {/*    <Switch location={location} key={location.pathname}> */}
        {/*      <motion.div */}
        {/*        initial="initial" */}
        {/*        animate="in" */}
        {/*        exit="out" */}
        {/*        variants={pageVariants} */}
        {/*        transition={pageTransition} */}
        {/*      > */}
        {/*        <Route path="/Homepage" component={Homepage} /> */}
        {/*        <Route */}
        {/*          path="/ProductsFilters" */}
        {/*          component={ProductsFilters} */}
        {/*        /> */}
        {/*      </motion.div> */}
        {/*    </Switch> */}
        {/*  </PresentationLayout> */}
        {/* </Route> */}

        <ProtectedRoute
          path={[
            '/emails',
            '/lists',
            '/domains',
            // '/Transactions',
            '/settings',
            '/api',
            '/dashboard',
          ]}
        >
          <LeftSidebar>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                <Route path="/emails" component={Emails} />
                <Route path="/lists" component={Lists} />
                <Route path="/domains" component={Domains} />
                {/* <Route path="/Transactions" component={Transactions} /> */}
                <Route path="/settings" component={Settings} />
                <Route path="/api" component={Api} />
                <Route path="/dashboard" component={Dashboard} />
              </motion.div>
            </Switch>
          </LeftSidebar>
        </ProtectedRoute>

        <AuthRoute
          path={[
            '/login',
            '/register',
            '/reset',
            // '/PageError404',
          ]}
        >
          <MinimalLayout>
            <Switch location={location} key={location.pathname}>
              {/* <motion.div */}
              {/*  initial="initial" */}
              {/*  animate="in" */}
              {/*  exit="out" */}
              {/*  variants={pageVariants} */}
              {/*  transition={pageTransition} */}
              {/* > */}
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/reset" exact component={ResetPassword} />
              {/* <Route path="/PageError404" component={PageError404} /> */}
              {/* </motion.div> */}
            </Switch>
          </MinimalLayout>
        </AuthRoute>
      </Switch>
      {/* </AnimatePresence> */}
    </>
  );
};

export default Routes;
