import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';

import { useTransition } from './utils';

export interface Resource<Params extends unknown[], Data> {
  read: () => Data;
  update: (...params: Params) => Resource<Params, Data>;
  refresh: () => void;
  key: () => string;
  name: () => string;
  shared: () => boolean;
}

let resourcesGlobalIndex = 0;

type CreateResourceOptions<Data> = {
  def?: Data;
  shared?: boolean;
};

export function createResource<Params extends unknown[], Data>(
  fetch: (...params: Params) => Promise<Data>,
  name: string,
  { def, shared }: CreateResourceOptions<Data> = {}
) {
  const key = `resource_${name}_${resourcesGlobalIndex}`;

  resourcesGlobalIndex++;

  let resource: Resource<Params, Data>;

  let result: Data = typeof def !== 'undefined' ? def : undefined!;
  let promise: Promise<Data>;

  const wrapPromise = (...params: Params): (() => Data) => {
    let status = 'pending';

    return () => {
      if (!promise) {
        promise = fetch(...params);
        status = 'pending';
      }

      const suspender = promise.then(
        (r) => {
          status = 'success';
          result = r;
        },
        (e) => {
          status = 'error';
          result = e;
        }
      );

      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else {
        return result;
      }
    };
  };

  return (...params: Params): Resource<Params, Data> => {
    if (resource) {
      return resource;
    }

    promise = typeof def !== 'undefined' ? Promise.resolve(def) : undefined!;
    const read = wrapPromise(...params);

    resource = {
      update(...newParams: Params) {
        promise = fetch(...(newParams.length ? newParams : params));
        const read = wrapPromise(...(newParams.length ? newParams : params));

        resource = {
          ...resource,
          read,
        };

        return resource;
      },
      refresh() {
        result = typeof def !== 'undefined' ? def : undefined!;
        promise = typeof def !== 'undefined' ? Promise.resolve(def) : undefined!;
      },
      name() {
        return name;
      },
      key() {
        return key;
      },
      shared() {
        return !!shared;
      },
      read,
    };

    return resource;
  };
}

type ResourceStore = {
  [name: string]: Resource<any, any>;
};

export function createResources<Params extends unknown[], Data>(
  fetch: (...params: Params) => Promise<Data>,
  name: string,
  options: CreateResourceOptions<Data> = {}
) {
  const resources: ResourceStore = {};

  return (...params: Params) => (id: string): Resource<Params, Data> => {
    if (!resources[id]) {
      resources[id] = createResource(fetch, `${name}_${id}`, options)(...params);
    }

    return resources[id];
  };
}

const SharedResourcesContext = createContext({
  // setResource: <Params extends unknown[], Data>(
  //   key: string,
  //   resource: Resource<Params, Data>
  // ): Resource<Params, Data> => {
  //   throw new Error('');
  // },
  getResource: <Params extends unknown[], Data>(
    initialResource: Resource<Params, Data>
  ): [Resource<Params, Data>, (resource: Resource<Params, Data>) => void] => {
    throw new Error('');
  },
});

export const SharedResources: FunctionComponent = ({ children }) => {
  const [resources, setResources] = useState<ResourceStore>({});

  const setResource = <Params extends unknown[], Data>(resource: Resource<Params, Data>) => {
    const key = resource.key();

    setResources({
      ...resources,
      [key]: resource,
    });

    return resource;
  };

  const context = {
    getResource<Params extends unknown[], Data>(
      initialResource: Resource<Params, Data>
    ): [Resource<Params, Data>, (resource: Resource<Params, Data>) => void] {
      const key = initialResource.key();

      let resource;

      if (!resources[key]) {
        resource = initialResource;
      } else {
        resource = resources[key];
      }

      return [resource, setResource];
    },
  };

  return <SharedResourcesContext.Provider value={context}>{children}</SharedResourcesContext.Provider>;
};

export function useResource<Params extends unknown[], Data>(
  initialResource: Resource<Params, Data>
): [Data, (...params: Params) => void] {
  let state;

  if (initialResource.shared()) {
    const { getResource } = useContext(SharedResourcesContext);
    state = getResource(initialResource);
  } else {
    state = useState(initialResource);
  }

  const [resource, setResource] = state;

  const updateResource = (...params: Params) => {
    setResource(resource.update(...params));
  };

  useEffect(
    () => () => {
      resource.refresh();
    },
    [resource.key]
  );

  const data = resource.read();

  return [data, updateResource];
}

export function useResourceUpdate<Params extends unknown[], Data>(
  initialResource: Resource<Params, Data>,
  onUpdate: (data: Exclude<Data, null>) => void
): [boolean, (...params: Params) => void, Data] {
  const [data, update] = useResource(initialResource);
  const [startTransition] = useTransition();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      startTransition(() => {
        onUpdate(data as Exclude<Data, null>);
        setLoading(false);
      });
    }
  }, [data]);

  const transition: (...params: Params) => void = (...params) => {
    if (!loading) {
      setLoading(true);
      startTransition(() => {
        update(...params);
      });
    }
  };

  return [loading, transition, data];
}
