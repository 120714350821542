import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { useThemeContext } from '../../index';
import HeaderUserBox from './UserBox';

const Header: FunctionComponent = () => {
  const { headerShadow, headerBgTransparent, sidebarToggleMobile, setSidebarToggleMobile } = useThemeContext();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent,
        })}
      >
        <div className="app-header--pane">
          <button
            className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
              'is-active': sidebarToggleMobile,
            })}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div className="app-header--pane">
          <HeaderUserBox />
        </div>
      </div>
    </>
  );
};

export default Header;
