import React from 'react';

import ErrorMessage from '../theme/Error';

export class ApiException extends Error {
  name = 'ApiException';

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ApiException.prototype);
  }
}

export class ApiAuthException extends ApiException {
  name = 'ApiAuthException';

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ApiAuthException.prototype);
  }
}

type ApiErrorBoundaryProps = {};

type ApiErrorBoundaryState = {
  error: boolean;
};

export class ErrorBoundary extends React.Component<ApiErrorBoundaryProps, ApiErrorBoundaryState> {
  state = {
    error: false,
  };

  static getDerivedStateFromError(_error: Error) {
    return {error: true};
  }

  componentDidCatch(_error: Error, _errorInfo: any) {
  }

  render() {
    if (this.state.error) {
      return <ErrorMessage/>;
    }

    return this.props.children;
  }
}
