import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import Header from '../Element/Minimum/Header';
import Footer from '../Element/Footer';

const MinimalLayout: FunctionComponent<{ id?: boolean }> = ({ children, id }) => (
  <div className={clsx('app-main', { id })}>
    <Header />
    <div className="app-content">
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper">{children}</div>
      </div>
      <Footer />
    </div>
  </div>
);

export default MinimalLayout;
