import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { useThemeContext } from '../../index';
import SidebarHeader from './Header';
import SidebarMenu from './Menu';

const Sidebar: FunctionComponent = () => {
  const { sidebarStyle, sidebarShadow, sidebarToggleMobile, setSidebarToggleMobile } = useThemeContext();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow,
        })}
      >
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile,
        })}
      />
    </>
  );
};

export default Sidebar;
