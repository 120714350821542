import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { useThemeContext } from '../../index';
import projectLogo from '../../../assets/images/logo.svg';
import projectHeader from '../../../assets/images/logo-text.svg';

const SidebarHeader = () => {
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = useThemeContext();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink to="/" title="" className="app-sidebar-logo">
            <div
              className="app-sidebar-logo--icon"
              style={{ background: 'none', boxShadow: 'none', height: 'auto', width: '35px' }}
            >
              <img alt="" style={{ animation: 'none', width: '100%' }} src={projectLogo} />
            </div>
            <div className="app-sidebar-logo--text">
              <img alt="Mailtumble" style={{ width: '125px' }} src={projectHeader} />
            </div>
          </NavLink>
        </div>
        <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button onClick={toggleSidebar} className="btn btn-sm collapse-sidebar-btn">
            <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
            'is-active': sidebarToggleMobile,
          })}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button onClick={toggleSidebar} className="expand-sidebar-btn btn btn-sm">
            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
export default SidebarHeader;
