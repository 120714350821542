import React, { useState, useContext, FunctionComponent } from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import shadows from './shadows';

const themeColors = {
  primary: {
    main: '#3c44b1',
  },
  grey: {
    300: '#fefefe',
    A100: '#f8f9ff',
  },
  secondary: {
    main: '#4191ff',
  },
  error: {
    main: '#f83245',
  },
  success: {
    main: '#1bc943',
  },
  info: {
    main: '#11c5db',
  },
  warning: {
    main: '#f4772e',
  },
  helpers: {
    primary: '#3c44b1',
    main: 'rgba(25, 46, 91, .035)',
  },
};

const MuiTheme = createMuiTheme({
  palette: {
    ...themeColors,
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1100,
      xl: 1381,
    },
  },
  shape: {
    borderRadius: '0.2rem' as any,
  },
  overrides: {
    MuiTab: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      sizeSmall: {
        padding: '6px 20px',
        fontSize: 14,
      },
      outlined: {
        padding: '10px 22px',
        fontSize: 14,
      },
      text: {
        padding: '10px 22px',
        fontSize: 14,
      },
      sizeLarge: {
        padding: '16px 28px',
        fontSize: 16,
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        padding: '10px 22px',
        fontSize: 14,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#070919',
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: '#070919',
      },
    },
  },
  typography: {
    fontFamily: ['Heebo', 'sans-serif'].join(','),
    fontSize: 14,
  },
  shadows,
});

const themeDefaults = {
  // Sidebar

  sidebarFixed: true,
  sidebarFooter: true,
  sidebarShadow: true,
  sidebarStyle: 'app-sidebar--light app-sidebar--shadow',
  sidebarToggleMobile: false,
  sidebarToggle: false,

  // Header

  headerFixed: true,
  headerShadow: true,
  headerBgTransparent: true,
  headerSearchHover: false,
  headerDrawerToggle: false,

  // Main content

  contentBackground: '',
  themeConfiguratorToggle: false,

  // Footer

  footerFixed: false,
  footerShadow: false,
  footerBgTransparent: true,

  // Page title

  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleIconBox: true,
  pageTitleDescription: true,
};

type ThemeColors = typeof themeColors;

type ThemeContext = {
  setSidebarToggleMobile: (sidebarToggleMobile: boolean) => void;
  setSidebarToggle: (sidebarToggle: boolean) => void;
  colors: ThemeColors;
} & typeof themeDefaults;

export const ThemeContext = React.createContext<ThemeContext | undefined>(undefined);

export const useThemeContext = (): ThemeContext => useContext(ThemeContext)!;

const Theme: FunctionComponent = ({ children }) => {
  const [sidebarToggleMobile, setSidebarToggleMobile] = useState(themeDefaults.sidebarToggleMobile);
  const [sidebarToggle, setSidebarToggle] = useState(themeDefaults.sidebarToggle);

  const context = {
    ...themeDefaults,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    sidebarToggle,
    setSidebarToggle,
    colors: themeColors,
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default Theme;
