import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { unstable_useTransition } from 'react';
import { EmailStatuses, Email } from '@mailtumble-next/email-core';

import { getToken, HintsConfig, User } from './auth';
import { Languages } from '../i18n';

export const getEmailErrMsg = (email: Email): string | undefined => {
  // Mapping has to be translated
  const msgMap: Record<keyof Omit<EmailStatuses, 'active'>, { [K in string]: string }> = {
    soft: {
      Undetermined: 'Undetermined soft error',
      General: 'General soft error',
      MailboxFull: 'Mailbox Full error',
      MessageTooLarge: 'Message too large error',
      ContentRejected: 'Content rejected error',
      AttachmentRejected: 'Attachment rejected error',
    },
    hard: {
      Undetermined: 'Undetermined hard error',
      General: 'General hard error',
      NoEmail: 'No email error',
      Suppressed: 'Suppressed error',
      OnAccountSuppressionList: 'On account suppression list hard error',
    },
    complain: {
      OnAccountSuppressionList: 'On account suppression list complain error',
      General: 'General complain error',
    },
    optout: {
      General: 'General optout error',
    },
    unverified: {
      General: 'The email address has not yet been verified by the user',
    },
  };

  const errKey = email.status.err;
  const errSubKey = 'msg' in email.status ? email.status.msg : 'General';

  return errKey ? msgMap[errKey][errSubKey] : undefined;
};

export const createClient = (config: AxiosRequestConfig = {}): AxiosInstance =>
  axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    timeout: 14000,
    ...config,
  });

export const request = (): AxiosInstance => {
  if (!process.env.REACT_APP_API_ROOT) {
    throw new Error('No API url');
  }

  const client = createClient();

  client.interceptors.request.use(async (config) => {
    const token = await getToken();

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return client;
};

export const cutUserAttributes = (user: any): User => {
  const { attributes } = user;

  return {
    logged: true,
    email: attributes.email as string,
    name: `${attributes.given_name} ${attributes.family_name}` || '',
    firstName: attributes.given_name,
    lastName: attributes.family_name,
    hints: JSON.parse(attributes['custom:hints'] || '{}') as HintsConfig,
    lang: attributes['custom:lang'] as Languages,
  };
};

export const getBrowserLang = (): Languages => {
  let language = (window.navigator
    ? window.navigator.language ||
      //@ts-ignore
      window.navigator.systemLanguage ||
      //@ts-ignore
      window.navigator.userLanguage
    : 'en') as Languages;
  language = language.substr(0, 2).toLowerCase() as Languages;

  return language;
};

export const useTransition = () => unstable_useTransition();
