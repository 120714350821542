import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useEffect } from 'react';
import { initReactI18next, Trans as TransBase, useTranslation as useTranslationBase } from 'react-i18next';

import { useAuthUpdate, useUser } from './api';
import { getBrowserLang, useTransition } from './api/utils';

export type Languages = 'ru' | 'en';

export const languages: { [K in string]: Languages } = {
  ru: 'ru',
  en: 'en',
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: languages.en, // use en if detected lng is not available
    react: {
      useSuspense: true,
    },
    ns: ['common', 'errors', 'countries'],
    defaultNS: 'common',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: '/lang/{{ns}}/{{lng}}.json',
    },
  });

export const Trans = TransBase;

export const useTranslation = (): ((key: string) => string) => {
  const { t } = useTranslationBase();

  return t;
};

export const useObjectTranslation = (): ((key: string) => { [key: string]: string }) => {
  const { t } = useTranslationBase();

  return (key) => t(key, { returnObjects: true });
};

export const useLangSwitcher = (): [string, (lang?: Languages, withOutSaving?: boolean) => void, boolean] => {
  const { i18n } = useTranslationBase();

  const modifyUser = useAuthUpdate();
  const [startTransition, isPending] = useTransition();

  const nextLang: Languages = i18n.language === languages.ru ? languages.en : languages.ru;

  const switchLang = (lang?: Languages, withoutSaving?: boolean) => {
    if (isPending) {
      return;
    }

    void i18n.changeLanguage(lang || nextLang);
    if (!withoutSaving) {
      startTransition(() => {
        return modifyUser({ type: 'modifyUser', attrs: { lang: lang || nextLang } });
      });
    }
  };

  return [i18n.language, switchLang, isPending];
};

export const useTranslationInApp = () => {
  const [_, switchLang] = useLangSwitcher();

  let userLogged = false;
  let languages: Languages = 'en';

  try {
    const { lang } = useUser();
    languages = lang;
    userLogged = true;
  } catch (e: any) {
    languages = getBrowserLang();
  }

  useEffect(() => {
    if (userLogged) {
      switchLang(languages, true);
    }
  }, []);
};

export default i18next;
