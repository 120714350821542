import React, { useState, FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { Menu, Button, List, ListItem } from '@material-ui/core';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import LanguageTwoToneIcon from '@material-ui/icons/Language';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';

import { useUser, useAuthUpdate } from '../../../api';
import { Trans, useLangSwitcher } from '../../../i18n';
import { useTransition } from '../../../api/utils';

const UserBox: FunctionComponent = () => {
  const user = useUser();
  const authUpdate = useAuthUpdate();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startTransition, isPendingHintsAll] = useTransition();
  const modifyUser = useAuthUpdate();

  const [currentLang, switchLang, isPendingLang] = useLangSwitcher();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authUpdate({ type: 'logout' });
  };

  const handleSettingsOption = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    handleClose();
    history.push('/settings');
  };

  const handleSwitchLanguage = () => {
    if (!isPendingLang) {
      switchLang();
    }
  };

  const showAllHintsHandle = () => {
    startTransition(() => {
      return modifyUser({
        type: 'modifyUser',
        attrs: {
          hints: {
            lists: true,
            domains: true,
            emails: true,
            dashboard: true,
            api: true,
          },
        },
      });
    });
  };

  return (
    <>
      <div className="user-box position-relative mr-2">
        <Button onClick={handleClick} className="btn-link p-0 text-left d-flex align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            {/* <div className="badge badge-success badge-circle p-top-a"> */}
            {/*  Online */}
            {/* </div> */}
            {/* <div className="avatar-icon rounded-sm"> */}
            {/*  <img src={avatar4} alt="..." /> */}
            {/* </div> */}
          </div>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold">{user.name}</div>
            <div className="text-black-50 mb-0">{user.email}</div>
            {/* <span className="text-danger"> */}
            {/*  <small>Shop Owner</small> */}
            {/* </span> */}
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
          </span>
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          classes={{ list: 'p-0' }}
          onClose={handleClose}
        >
          <div className="dropdown-menu-lg overflow-hidden p-0">
            <div className="d-flex p-4">
              {/* <div className="avatar-icon rounded mr-3 d-50"> */}
              {/*  <img src={avatar4} alt="..."/> */}
              {/* </div> */}
              <div>
                <h6 className="font-weight-bold mb-1 text-black">{user.name}</h6>
                <p className="text-black-50 mb-0">{user.email}</p>
              </div>
            </div>
            <div className="divider" />

            <List component="div" className="nav-neutral-first nav-pills-rounded flex-column p-3">
              <ListItem component="a" button disabled={isPendingHintsAll} onClick={showAllHintsHandle}>
                <div className="mr-2">
                  <SchoolTwoToneIcon />
                </div>
                <span className="font-size-md">
                  <Trans>Show all hints</Trans>
                </span>
              </ListItem>
            </List>
            <div className="divider" />

            <List component="div" className="nav-neutral-first nav-pills-rounded flex-column p-3">
              <ListItem component="a" button disabled={isPendingLang} onClick={handleSwitchLanguage}>
                <div className="mr-2">
                  <LanguageTwoToneIcon />
                </div>
                <span className="font-size-md">
                  <Trans>{currentLang}</Trans>
                </span>
              </ListItem>
            </List>
            <div className="divider" />

            <List component="div" className="nav-neutral-first nav-pills-rounded flex-column p-3">
              <a onClick={handleSettingsOption}>
                <ListItem button>
                  <div className="mr-2">
                    <SettingsTwoToneIcon />
                  </div>
                  <span className="font-size-md">
                    <Trans>Settings</Trans>
                  </span>
                </ListItem>
              </a>
            </List>
            <div className="divider" />

            <List component="div" className="nav-neutral-danger nav-pills-rounded flex-column p-3">
              <ListItem component="a" button onClick={handleLogout}>
                <div className="mr-2">
                  <ExitToAppTwoToneIcon />
                </div>
                <span>
                  <Trans>Log out</Trans>
                </span>
              </ListItem>
            </List>
          </div>
        </Menu>
      </div>
    </>
  );
};

export default UserBox;
