import React, { FunctionComponent } from 'react';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import PhoneInTalkTwoToneIcon from '@material-ui/icons/PhoneInTalkTwoTone';

import { Trans } from '../../../i18n';

const Contacts: FunctionComponent = () => (
  <div
    className="sidebar-menu-box"
    style={{
      marginTop: 'auto',
    }}
  >
    <div className="sidebar-header">
      <span>
        <Trans>Contacts</Trans>
      </span>
    </div>
    <ul>
      <li>
        <a className="font-weight-normal" href="tel:+61412341234" rel="noopener noreferrer">
          <span className="sidebar-icon">
            <PhoneInTalkTwoToneIcon />
          </span>
          <span className="sidebar-item-label">+1 585 488 6253</span>
        </a>
      </li>
      <li>
        <a className="font-weight-normal" href="mailto:support@mailtumble.app" rel="noopener noreferrer">
          <span className="sidebar-icon">
            <MailTwoToneIcon />
          </span>
          <span className="sidebar-item-label">support@mailtumble.app</span>
        </a>
      </li>
      {/* <li> */}
      {/*  <a */}
      {/*    className="font-weight-normal" */}
      {/*    href="https://docs.uifort.com/bamburgh-react-admin-dashboard-pro-docs/TechnicalSupport" */}
      {/*    target="_blank" */}
      {/*    rel="noopener noreferrer" */}
      {/*  > */}
      {/*    <span className="sidebar-icon"> */}
      {/*      <LinkTwoToneIcon /> */}
      {/*    </span> */}
      {/*  </a> */}
      {/* </li> */}
      {/* <li> */}
      {/*  <a href="#/" className={clsx('font-weight-normal')}> */}
      {/*    <span className="sidebar-icon"> */}
      {/*      <LinkTwoToneIcon /> */}
      {/*    </span> */}
      {/*    <span className="sidebar-item-label text-capitalize">Dropdowns Examples</span> */}
      {/*    <span className="sidebar-icon-indicator"> */}
      {/*      <ChevronRightTwoToneIcon /> */}
      {/*    </span> */}
      {/*  </a> */}
      {/*  <Collapse in> */}
      {/*    <ul className="pb-0"> */}
      {/*      <li> */}
      {/*        <a href="#/" onClick={(e) => e.preventDefault()}> */}
      {/*          <span className="sidebar-icon"> */}
      {/*            <LinkTwoToneIcon /> */}
      {/*          </span> */}
      {/*          <span className="sidebar-item-label">Second Level Link 1</span> */}
      {/*        </a> */}
      {/*      </li> */}
      {/*      <li> */}
      {/*        <a href="#/" onClick={(e) => e.preventDefault()}> */}
      {/*          <span className="sidebar-icon"> */}
      {/*            <LinkTwoToneIcon /> */}
      {/*          </span> */}
      {/*          <span className="sidebar-item-label">Second Level Link 1</span> */}
      {/*        </a> */}
      {/*      </li> */}
      {/*    </ul> */}
      {/*  </Collapse> */}
      {/* </li> */}
    </ul>
  </div>
);

export default Contacts;
