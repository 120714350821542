import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { useThemeContext } from '../../index';

const Footer: FunctionComponent = () => {
  const { footerShadow, footerBgTransparent } = useThemeContext();

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent,
        })}
      >
        <div className="app-footer--first">
          {/* <List component="div" className="d-flex align-items-center"> */}
          {/*  <ListItem */}
          {/*    button */}
          {/*    component={Link} */}
          {/*    to="/Homepage" */}
          {/*    className="rounded-sm" */}
          {/*  > */}
          {/*    Home */}
          {/*  </ListItem> */}
          {/*  <ListItem */}
          {/*    button */}
          {/*    component={Link} */}
          {/*    to="/Products" */}
          {/*    className="rounded-sm" */}
          {/*  > */}
          {/*    Products */}
          {/*  </ListItem> */}
          {/*  <ListItem */}
          {/*    button */}
          {/*    component={Link} */}
          {/*    to="/Orders" */}
          {/*    className="rounded-sm" */}
          {/*  > */}
          {/*    Orders */}
          {/*  </ListItem> */}
          {/* </List> */}
        </div>
        <div className="app-footer--second">
          <b>Mailtumble</b> © 2021
        </div>
      </div>
    </>
  );
};

export default Footer;
