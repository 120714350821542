import React, { FunctionComponent, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './theme/icons';
import Api from './api';
import { SharedResources } from './api/resource';
import { useTranslationInApp } from './i18n';
import Theme from './theme';
import Loading from './theme/Loading';
import Routes from './Routes';
import ScrollToTop from './theme/ScrollToTop';
import './assets/base.scss';

const TranslatedApp = withTranslation()(() => {
  useTranslationInApp();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes />
    </BrowserRouter>
  );
});

const App: FunctionComponent = () => {
  return (
    <Theme>
      <Suspense fallback={<Loading />}>
        <SharedResources>
          <Api>
            <TranslatedApp />
          </Api>
        </SharedResources>
      </Suspense>
    </Theme>
  );
};

export default App;
