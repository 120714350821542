import AwsAuth from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { createResource } from './resource';
import { Languages } from '../i18n';
import { CognitoAttributes } from './user';
import { cutUserAttributes, getBrowserLang } from './utils';

export type HintBoxId = 'lists' | 'emails' | 'dashboard' | 'domains' | 'api';

export type HintsConfig = {
  [K in HintBoxId]: boolean;
};

export interface User {
  logged: true;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  hints: HintsConfig;
  lang: Languages;
}

export type FlexibleUserAttributes = Pick<User, 'lang' | 'hints' | 'firstName' | 'lastName'>;

export interface Guest {
  logged: false;
}

export type Authed = User | Guest;

type CommonErrors = 'InternalErrorException' | 'NetworkError' | 'UnknownError';

export type LoginErrors =
  | 'WrongPassword'
  | 'UserNotConfirmedException'
  | 'PasswordResetRequiredException'
  | 'CodeMismatchException'
  | 'EnableSoftwareTokenMFAException'
  | CommonErrors;

export type LoginConfirmSMSErrors = CommonErrors | 'CodeMismatchException';

export type LoginConfirmTOTPErrors = CommonErrors | 'CodeMismatchException' | 'EnableSoftwareTokenMFAException';

export type ModifyProgress = {
  type: 'modifyUser';
};

export type LoginProgress = {
  type: 'login';
  error: LoginErrors;
};

export type LoginConfirmSMSProgress = {
  type: 'loginConfirmSMS';
  userAuth: CognitoUser;
};

export type LoginConfirmSMSProgressError = LoginConfirmSMSProgress & {
  error: LoginConfirmSMSErrors;
};

export type LoginConfirmTOTPProgress = {
  type: 'loginConfirmTOTP';
  userAuth: CognitoUser;
};

export type LoginConfirmTOTPProgressError = LoginConfirmTOTPProgress & {
  error: LoginConfirmTOTPErrors;
};

export type LoginConfirmSMSProgressGroup = LoginConfirmSMSProgress | LoginConfirmSMSProgressError;

export type LoginConfirmTOTPProgressGroup = LoginConfirmTOTPProgress | LoginConfirmTOTPProgressError;

export type RegisterErrors = 'ConfirmationRequired' | 'UsernameExistsException' | CommonErrors;

export type RegisterProgress = {
  type: 'register';
  error: RegisterErrors;
};

export type ResetPasswordInitErrors =
  | (
      | 'UserNotFoundException'
      | 'UserNotConfirmedException'
      | 'CodeDeliveryFailureException'
      | 'LimitExceededException'
      | 'TooManyRequestsException'
    )
  | CommonErrors;

export type ResetPasswordVerificationErrors =
  | (
      | 'UserNotConfirmedException'
      | 'CodeMismatchException'
      | 'ExpiredCodeException'
      | 'InvalidPasswordException'
      | 'LimitExceededException'
      | 'TooManyRequestsException'
      | 'TooManyFailedAttemptsException'
    )
  | CommonErrors;

export type ResetPasswordInitProgress = {
  type: 'ResetPasswordInit';
};

export type ResetPasswordInitProgressError = {
  type: 'ResetPasswordInitError';
  error: ResetPasswordInitErrors;
};

export type ResetPasswordInitProgressGroup = ResetPasswordInitProgress | ResetPasswordInitProgressError;

export type ResetPasswordVerificationProgress = {
  type: 'ResetPasswordVerification';
  email: string;
};

export type ResetPasswordVerificationProgressErr = {
  type: 'ResetPasswordVerificationError';
  error: ResetPasswordVerificationErrors;
  email: string;
};

export type ResetPasswordVerificationProgressSuccess = {
  type: 'ResetPasswordVerificationSuccess';
};

export type ResetPasswordVerificationProgressGroup =
  | ResetPasswordVerificationProgress
  | ResetPasswordVerificationProgressErr
  | ResetPasswordVerificationProgressSuccess;

export interface Progress<P> {
  logged: null;
  progress: P;
}

export type Auth =
  | Authed
  | Progress<
      | RegisterProgress
      | LoginProgress
      | LoginConfirmSMSProgressGroup
      | LoginConfirmTOTPProgressGroup
      | ResetPasswordInitProgressGroup
      | ResetPasswordVerificationProgressGroup
    >;

export type ActionRegister = {
  type: 'register';
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type ActionLogin = {
  type: 'login';
  email: string;
  password: string;
};

export type ActionLoginConfirmSMS = {
  type: 'loginConfirmSMS';
  userAuth: CognitoUser; // Returns from Auth.signIn()
  code: string;
};

export type ActionLoginConfirmTOTP = {
  type: 'loginConfirmTOTP';
  userAuth: CognitoUser; // Returns from Auth.signIn()
  code: string;
};

export type ActionLogout = {
  type: 'logout';
};

export type ActionResetPasswordInit = {
  type: 'ResetPasswordInit';
  email: string;
};

export type ActionResetPasswordConfirm = {
  type: 'ResetPasswordConfirm';
  email: string;
  code: string;
  password: string;
};

export type ActionModify = {
  type: 'modifyUser';
  attrs: Partial<FlexibleUserAttributes>;
};

export type AuthActions =
  | ActionRegister
  | ActionLogin
  | ActionLoginConfirmSMS
  | ActionLoginConfirmTOTP
  | ActionLogout
  | ActionResetPasswordInit
  | ActionResetPasswordConfirm
  | ActionModify;

export const configure = (): void => {
  AwsAuth.configure({
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict' | 'lax',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: 'your_cognito_domain',
    //   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: 'http://localhost:3000/',
    //   redirectSignOut: 'http://localhost:3000/',
    //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  });
};

export const getAuthUser = async (): Promise<Authed> => {
  try {
    const user = await AwsAuth.currentAuthenticatedUser();

    return cutUserAttributes(user);
  } catch (err) {
    return { logged: false };
  }
};

const register = async (action: ActionRegister): Promise<Progress<RegisterProgress>> => {
  try {
    const { user } = await AwsAuth.signUp({
      username: action.email,
      password: action.password,
      attributes: {
        given_name: action.firstName,
        family_name: action.lastName,
        'custom:lang': getBrowserLang(),
        'custom:hints': JSON.stringify({
          lists: true,
          emails: true,
          dashboard: true,
          domains: true,
          api: true,
        }),
      },
    });

    return {
      logged: null,
      progress: {
        type: 'register',
        error: 'ConfirmationRequired',
      },
    };
  } catch (err: any) {
    let errorCode: RegisterErrors = 'UnknownError';

    switch (err.code) {
      case 'NetworkError':
        errorCode = 'NetworkError';
        break;
      case 'UsernameExistsException':
        errorCode = 'UsernameExistsException';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'register',
        error: errorCode,
      },
    };
  }
};

const login = async (
  action: ActionLogin
): Promise<Authed | Progress<LoginProgress | LoginConfirmSMSProgressGroup | LoginConfirmTOTPProgressGroup>> => {
  try {
    const userAuth = await AwsAuth.signIn(action.email, action.password);

    if (userAuth.challengeName === 'SMS_MFA') {
      return {
        logged: null,
        progress: {
          type: 'loginConfirmSMS',
          userAuth,
        },
      };
    }

    if (userAuth.challengeName === 'SOFTWARE_TOKEN_MFA') {
      return {
        logged: null,
        progress: {
          type: 'loginConfirmTOTP',
          userAuth,
        },
      };
    }

    return await getAuthUser();
  } catch (err: any) {
    let errorCode: LoginErrors = 'UnknownError';

    switch (err.code) {
      case 'NetworkError':
        errorCode = 'NetworkError';
        break;
      case 'PasswordResetRequiredException':
        errorCode = 'PasswordResetRequiredException';
        break;
      case 'InternalErrorException':
        errorCode = 'InternalErrorException';
        break;
      case 'UserNotConfirmedException':
        errorCode = 'UserNotConfirmedException';
        break;
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        errorCode = 'WrongPassword';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'login',
        error: errorCode,
      },
    };
  }
};

const loginConfirmSMS = async (
  action: ActionLoginConfirmSMS
): Promise<Authed | Progress<LoginConfirmSMSProgressGroup>> => {
  try {
    await AwsAuth.confirmSignIn(action.userAuth, action.code, 'SMS_MFA');

    return await getAuthUser();
  } catch (err: any) {
    let errorCode: LoginErrors = 'UnknownError';

    switch (err.code) {
      case 'NetworkError':
        errorCode = 'NetworkError';
        break;
      case 'InternalErrorException':
        errorCode = 'InternalErrorException';
        break;
      case 'CodeMismatchException':
        errorCode = 'CodeMismatchException';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'loginConfirmSMS',
        error: errorCode,
        userAuth: action.userAuth,
      },
    };
  }
};

const loginConfirmTOTP = async (
  action: ActionLoginConfirmTOTP
): Promise<Authed | Progress<LoginConfirmTOTPProgressGroup>> => {
  try {
    await AwsAuth.confirmSignIn(action.userAuth, action.code, 'SOFTWARE_TOKEN_MFA');

    return await getAuthUser();
  } catch (err: any) {
    let errorCode: LoginErrors = 'UnknownError';

    switch (err.code) {
      case 'NetworkError':
        errorCode = 'NetworkError';
        break;
      case 'InternalErrorException':
        errorCode = 'InternalErrorException';
        break;
      case 'CodeMismatchException':
        errorCode = 'CodeMismatchException';
        break;
      case 'EnableSoftwareTokenMFAException':
        errorCode = 'EnableSoftwareTokenMFAException';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'loginConfirmTOTP',
        error: errorCode,
        userAuth: action.userAuth,
      },
    };
  }
};

export const logout = async (): Promise<Authed> => {
  await AwsAuth.signOut();

  return getAuthUser();
};

export const resetPasswordInit = async (
  action: ActionResetPasswordInit
): Promise<Progress<ResetPasswordInitProgressGroup | ResetPasswordVerificationProgress>> => {
  try {
    await AwsAuth.forgotPassword(action.email);

    return {
      logged: null,
      progress: {
        type: 'ResetPasswordVerification',
        email: action.email,
      },
    };
  } catch (err: any) {
    let errorCode: ResetPasswordInitErrors = 'UnknownError';

    switch (err.code) {
      case 'InternalErrorException':
      case 'NetworkError':
      case 'UserNotFoundException':
      case 'UserNotConfirmedException':
      case 'CodeDeliveryFailureException':
        errorCode = err.code;
        break;
      case 'LimitExceededException':
      case 'TooManyRequestsException':
        errorCode = 'TooManyRequestsException';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'ResetPasswordInitError',
        error: errorCode,
      },
    };
  }
};

export const resetPasswordVerification = async (
  action: ActionResetPasswordConfirm
): Promise<Progress<ResetPasswordVerificationProgressGroup>> => {
  try {
    await AwsAuth.forgotPasswordSubmit(action.email, action.code, action.password);

    return {
      logged: null,
      progress: {
        type: 'ResetPasswordVerificationSuccess',
      },
    };
  } catch (err: any) {
    let errorCode: ResetPasswordVerificationErrors = 'UnknownError';

    switch (err.code) {
      case 'InternalErrorException':
      case 'NetworkError':
      case 'UserNotConfirmedException':
      case 'CodeMismatchException':
      case 'ExpiredCodeException':
      case 'InvalidPasswordException':
      case 'LimitExceededException':
        errorCode = err.code;
        break;
      case 'TooManyRequestsException':
      case 'TooManyFailedAttemptsException':
        errorCode = 'TooManyRequestsException';
        break;
      default:
    }

    return {
      logged: null,
      progress: {
        type: 'ResetPasswordVerificationError',
        error: errorCode,
        email: action.email,
      },
    };
  }
};

const modifyUser = async (action: ActionModify): Promise<User> => {
  const { attrs } = action;

  const newAttributes: Partial<CognitoAttributes> = {};

  if (attrs.hints) {
    newAttributes['custom:hints'] = JSON.stringify(attrs.hints);
  }
  if (attrs.lang) {
    newAttributes['custom:lang'] = attrs.lang;
  }
  if (attrs.firstName) {
    newAttributes.given_name = attrs.firstName;
  }
  if (attrs.lastName) {
    newAttributes.family_name = attrs.lastName;
  }

  const user = await AwsAuth.currentAuthenticatedUser({ bypassCache: true });

  await AwsAuth.updateUserAttributes(user, newAttributes);

  user.attributes = {
    ...user.attributes,
    ...newAttributes,
  };

  return cutUserAttributes(user);
};

export const getAuth = async (action?: AuthActions): Promise<Auth> => {
  if (!action) {
    return getAuthUser();
  }
  if (action.type === 'modifyUser') {
    return modifyUser(action);
  }
  if (action.type === 'register') {
    return register(action);
  }
  if (action.type === 'login') {
    return login(action);
  }
  if (action.type === 'loginConfirmSMS') {
    return loginConfirmSMS(action);
  }
  if (action.type === 'loginConfirmTOTP') {
    return loginConfirmTOTP(action);
  }
  if (action.type === 'ResetPasswordInit') {
    return resetPasswordInit(action);
  }
  if (action.type === 'ResetPasswordConfirm') {
    return resetPasswordVerification(action);
  }

  return logout();
};

export const getToken = async (): Promise<string> => {
  const result = await AwsAuth.currentSession();

  return result.getIdToken().getJwtToken();
};

const auth = createResource(getAuth, 'auth')();

export default auth;

configure();
