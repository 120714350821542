import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, Container, Dialog, Button, TextField } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import projectLogo from '../../../assets/images/logo.png';
import projectHeader from '../../../assets/images/logo-text.svg';
import people2 from '../../../assets/images/stock-photos/people-2.jpg';

const Header = () => {
  const [loginModal, setLoginModal] = useState(false);
  const toggleLogin = () => setLoginModal(!loginModal);

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <>
      <div className="bg-white flex-column header-nav-wrapper navbar-light">
        <Container className="d-flex align-items-center py-4">
          <div className="app-nav-logo flex-grow-0">
            <NavLink to="/" title="" className="app-nav-logo app-nav-logo--dark">
              <div className="app-nav-logo--icon" style={{ background: 'none', boxShadow: 'none', border: 'none' }}>
                <img alt="Mailtumble" style={{ animation: 'none', width: '100%' }} src={projectLogo} />
              </div>
              <div className="app-nav-logo--text">
                <img alt="Mailtumble" style={{ width: '125px' }} src={projectHeader} />
              </div>
            </NavLink>
          </div>
        </Container>
      </div>
      <div className="divider bg-black-10" />
      <div className={clsx('collapse-page-trigger', { 'is-active': collapse })} onClick={toggle} />
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={loginModal}
        onClose={toggleLogin}
        classes={{
          paper: 'rounded border-0 shadow-sm-dark bg-white p-3 p-xl-0',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xl={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-skim-blue h-100 rounded br-xl-right-0">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image rounded br-xl-right-0 opacity-7"
                  style={{ backgroundImage: `url(${people2})` }}
                />
                <div className="bg-composed-wrapper--bg bg-second opacity-1 rounded br-xl-right-0" />
                <div className="bg-composed-wrapper--bg bg-slick-carbon opacity-5 rounded br-xl-right-0" />
                <div className="bg-composed-wrapper--content justify-content-center text-center text-xl-left p-5">
                  <div className="text-white text-center">
                    <h1 className="display-3 my-3 font-weight-bold">Register</h1>
                    <p className="font-size-lg mb-0 px-4 text-white-50">
                      All components from the General dashboard template can be used in the individual applications
                      pages, without modifications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xl={7}>
            <Grid item lg={8} xl={10} className="mx-auto">
              <div className="bg-white p-4 rounded">
                <div className="text-center my-4">
                  <h1 className="display-4 mb-1 font-weight-bold">Create your account</h1>
                  <p className="font-size-lg mb-0 text-black-50">Start benefiting from our tools right away</p>
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold mb-2">Email address</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Enter your email address"
                    type="email"
                  />
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="font-weight-bold mb-2">Password</label>
                  </div>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Enter your password"
                    type="password"
                  />
                </div>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <div className="mb-3">
                      <label className="font-weight-bold mb-2">First name</label>
                      <TextField variant="outlined" size="small" fullWidth placeholder="Enter your first name" />
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="mb-3">
                      <label className="font-weight-bold mb-2">Last name</label>
                      <TextField variant="outlined" size="small" fullWidth placeholder="Enter your last name" />
                    </div>
                  </Grid>
                </Grid>
                <div className="mb-4">
                  By clicking the <strong>Create account</strong> button below you agree to our terms of service and
                  privacy statement.
                </div>
                <div className="text-center mb-4">
                  <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
                    Create account
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Header;
