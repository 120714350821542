import React, { FunctionComponent, useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { ErrorBoundary, ApiException } from './errors';
import authResource, {
  Auth,
  User,
  AuthActions,
  LoginProgress,
  RegisterProgress,
  ResetPasswordInitProgressGroup,
  ResetPasswordVerificationProgressGroup,
  LoginConfirmSMSProgressGroup,
  LoginConfirmTOTPProgressGroup,
} from './auth';
import { useResource } from './resource';

interface AppApi {
  auth: Auth;
  authUpdate: (action?: AuthActions) => void;
}

export const ApiContext = React.createContext<AppApi>(undefined!);

export const useAuth = (): Auth => {
  const context = useContext(ApiContext);

  return context.auth;
};

export const useUser = (): User => {
  const context = useContext(ApiContext);

  const { auth } = context;

  if (!auth.logged) {
    throw new ApiException('No logged user');
  }

  return auth;
};

export const useAuthUpdate = (): AppApi['authUpdate'] => {
  const context = useContext(ApiContext);

  return context.authUpdate;
};

export const useAuthProgress = ():
  | LoginProgress
  | LoginConfirmSMSProgressGroup
  | LoginConfirmTOTPProgressGroup
  | RegisterProgress
  | ResetPasswordInitProgressGroup
  | ResetPasswordVerificationProgressGroup
  | null => {
  const context = useContext(ApiContext);

  const { auth } = context;

  if (auth.logged === null) {
    return auth.progress;
  }

  return null;
};

export const useLoginProgress = ():
  | LoginProgress
  | LoginConfirmSMSProgressGroup
  | LoginConfirmTOTPProgressGroup
  | null => {
  const progress = useAuthProgress();

  return progress &&
    (progress.type === 'login' || progress.type === 'loginConfirmSMS' || progress.type === 'loginConfirmTOTP')
    ? progress
    : null;
};

export const useRegisterProgress = (): RegisterProgress | null => {
  const progress = useAuthProgress();

  return progress && progress.type === 'register' ? progress : null;
};

export const useResetPasswordProgress = ():
  | ResetPasswordInitProgressGroup
  | ResetPasswordVerificationProgressGroup
  | null => {
  const progress = useAuthProgress();

  if (
    progress &&
    (progress.type === 'ResetPasswordInit' ||
      progress.type === 'ResetPasswordInitError' ||
      progress.type === 'ResetPasswordVerification' ||
      progress.type === 'ResetPasswordVerificationError' ||
      progress.type === 'ResetPasswordVerificationSuccess')
  ) {
    return progress;
  }

  return null;
};

export const ProtectedRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const user = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.logged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const AuthRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const user = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user.logged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Api: FunctionComponent = ({ children }) => {
  const [auth, authUpdate] = useResource(authResource);

  const api = {
    auth,
    authUpdate,
  };

  return (
    <ErrorBoundary>
      <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    </ErrorBoundary>
  );
};

export default Api;
