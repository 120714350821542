import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import { Trans } from '../i18n';

const Loading = () => (
  <>
    <AnimatePresence>
      <motion.div
        key="loading"
        initial={{ opacity: 0.25 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
        style={{
          zIndex: 99999,
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'white',
        }}
      >
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color="#3c44b1" loading />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            {/* Please wait */}
            <span className="font-size-lg d-block text-dark">
              <Trans>Please wait...</Trans>
            </span>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  </>
);
export default Loading;
