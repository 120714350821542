import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import DomainTwoToneIcon from '@material-ui/icons/DomainTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import DnsDnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';

import { useThemeContext } from '../../index';
import { Trans } from '../../../i18n';
import Contacts from './Contacts';

const SidebarMenu: FunctionComponent = () => {
  const { setSidebarToggleMobile } = useThemeContext();

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  return (
    <>
      <PerfectScrollbar>
        <div
          className="sidebar-navigation"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/dashboard"
              >
                <span className="sidebar-icon">
                  <AssessmentTwoToneIcon />
                </span>
                <Trans>Dashboard</Trans>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={toggleSidebarMobile} activeClassName="active" className="nav-link-simple" to="/lists">
                <span className="sidebar-icon">
                  <DnsDnsTwoToneIcon />
                </span>
                <Trans>Lists</Trans>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={toggleSidebarMobile} activeClassName="active" className="nav-link-simple" to="/emails">
                <span className="sidebar-icon">
                  <EmailTwoToneIcon />
                </span>
                <Trans>Emails</Trans>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={toggleSidebarMobile} activeClassName="active" className="nav-link-simple" to="/domains">
                <span className="sidebar-icon">
                  <DomainTwoToneIcon />
                </span>
                <Trans>Domains</Trans>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={toggleSidebarMobile} activeClassName="active" className="nav-link-simple" to="/api">
                <span className="sidebar-icon">
                  <DashboardTwoToneIcon />
                </span>
                <Trans>API</Trans>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
          </ul>
          <Contacts />
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default SidebarMenu;
